import { useMemo } from "react";
import urlStrings from "../../constants/urlStrings";
import useScript from "../../hooks/useScript";
import GoogleAuthContext from "./GoogleAuthContext";

/** @typedef {import("./GoogleAuthContext").GoogleAuthContext} GoogleAuthContext */

/**
 * @typedef GoogleAuthProviderProps
 * @property {React.ReactElement} children - Children.
 */

/**
 * GoogleAuthProvider
 * @param {GoogleAuthProviderProps} props - GoogleAuthProvider props.
 * @returns {React.ReactElement} - GoogleAuthProvider component.
 */
const GoogleAuthProvider = ({ children }) => {
    const scriptLoadedSuccessfully = useScript(urlStrings.googleClientLibrary, []);

    const context = useMemo(
        () => ({
            scriptLoadedSuccessfully,
        }),
        [scriptLoadedSuccessfully]
    );

    return <GoogleAuthContext.Provider value={context}>{children}</GoogleAuthContext.Provider>;
};

export default GoogleAuthProvider;
