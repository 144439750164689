import PropTypes from "prop-types";
import styled from "styled-components";

const StyledContainer = styled.div.attrs((props) => ({
    id: props.id,
}))`
    display: var(--containerDisplay, block);
    flex-direction: var(--containerFlexDirection);
    align-items: var(--containerAlignItems);
    border: var(--containerBorder);
    border-radius: var(--containerBorderRadius);
    justify-content: var(--containerJustifyContent);
    flex: var(--containerFlex);
    flex-wrap: var(--containerFlexWrap);
    text-align: var(--containerTextAlign);
    height: var(--containerHeight);
    max-height: var(--containerMinHeight);
    margin: var(--containerMargin);
    margin-top: var(--containerMarginTop);
    margin-right: var(--containerMarginRight);
    margin-left: var(--containerMarginLeft);
    margin-bottom: var(--containerMarginBottom);
    padding: var(--containerPadding);
    position: var(--containerPosition);
    top: var(--containerTop);
    background: var(--containerBackground);
    z-index: var(--containerZIndex);
    min-width: var(--containerMinWidth);
    max-width: var(--containerMaxWidth);
    overflow-y: var(--containerOverflowY);
    width: var(--containerWidth);
`;

const Container = (props) => {
    return (
        <StyledContainer
            id={props.id}
            className={props.className}
            aria-labelledby={props["aria-labelledby"]}
            style={{
                "--containerDisplay": props.display,
                "--containerFlexDirection": props.flexDirection,
                "--containerAlignItems": props.alignItems,
                "--containerBorder": props.border,
                "--containerBorderRadius": props.borderRadius,
                "--containerJustifyContent": props.justifyContent,
                "--containerFlex": props.flex,
                "--containerFlexWrap": props.flexWrap,
                "--containerTextAlign": props.textAlign,
                "--containerHeight": props.height,
                "--containerMinHeight": props.minHeight,
                "--containerMargin": props.margin,
                "--containerMarginBottom": props.marginBottom,
                "--containerPadding": props.padding,
                "--containerPosition": props.position,
                "--containerTop": props.top,
                "--containerBackground": props.background,
                "--containerZIndex": props.zIndex,
                "--containerMarginTop": props.marginTop,
                "--containerMarginRight": props.marginRight,
                "--containerMarginLeft": props.marginLeft,
                "--containerMinWidth": props.minWidth,
                "--containerMaxWidth": props.maxWidth,
                "--containerOverflowY": props.overflowY,
                "--containerWidth": props.width,
            }}
        >
            {props.children}
        </StyledContainer>
    );
};

Container.propTypes = {
    id: PropTypes.string,
    className: PropTypes.string,
    display: PropTypes.string,
    flexDirection: PropTypes.string,
    alignItems: PropTypes.string,
    border: PropTypes.string,
    borderRadius: PropTypes.string,
    justifyContent: PropTypes.string,
    flex: PropTypes.number,
    flexWrap: PropTypes.string,
    textAlign: PropTypes.string,
    height: PropTypes.string,
    minHeight: PropTypes.string,
    margin: PropTypes.string,
    marginBottom: PropTypes.string,
    padding: PropTypes.string,
    position: PropTypes.string,
    top: PropTypes.string,
    background: PropTypes.string,
    zIndex: PropTypes.number,
    marginTop: PropTypes.string,
    marginRight: PropTypes.string,
    marginLeft: PropTypes.string,
    minWidth: PropTypes.string,
    maxWidth: PropTypes.string,
    overflowY: PropTypes.string,
    children: PropTypes.node.isRequired,
    width: PropTypes.string,
    "aria-labelledby": PropTypes.string,
};

export default Container;
