import { usePromiseTracker } from "react-promise-tracker";
import { useIsFetching } from "react-query";
import useAuth from "../../../provider/auth/hooks/useAuth";
import LoadingOverlay from "../LoadingOverlay/LoadingOverlay";

const Preloader = () => {
    const { promiseInProgress } = usePromiseTracker();
    /*
     *  For the current long-polling in SendBySelfForm's useDemandLettersQuery, the promise tracker wasn't showing the desired behavior
     *  so we use this react-query hook to return a flag indicating the long-polling is in progress. This should only matter for the
     *  userDemandLettersQuery api call, and no other react-query wrapped api calls.
     */
    const isFetching = useIsFetching(["demandLetterPdf"]);

    const { isValidating } = useAuth();

    if (!promiseInProgress && !isFetching && !isValidating) {
        return null;
    }

    return <LoadingOverlay />;
};

export default Preloader;
