import CheckCircleOutlineOutlinedIcon from "@mui/icons-material/CheckCircleOutlineOutlined";
import ErrorOutline from "@mui/icons-material/ErrorOutline";
import InfoOutlined from "@mui/icons-material/InfoOutlined";
import PropTypes from "prop-types";
import { variantColorMap, AlertVariantKind } from "../../../constants/alertVariantKinds";

const DEFAULT_FONT_SIZE = "inherit";
const DEFAULT_COLOR = variantColorMap[AlertVariantKind.Success];

const AlertIcon = ({
    variant = AlertVariantKind.Success,
    fontSize = DEFAULT_FONT_SIZE,
    IconProps,
}) => {
    const color = variantColorMap[variant];

    switch (variant) {
        case AlertVariantKind.Error:
            return <ErrorOutline style={{ fontSize, color }} {...IconProps} />;
        case AlertVariantKind.Info:
            return <InfoOutlined style={{ fontSize, color }} {...IconProps} />;
        case AlertVariantKind.Warning:
            return <ErrorOutline style={{ fontSize, color }} {...IconProps} />;
        default:
            // no valid variant or success
            return (
                <CheckCircleOutlineOutlinedIcon
                    style={{ fontSize, color: DEFAULT_COLOR }}
                    {...IconProps}
                />
            );
    }
};

AlertIcon.propTypes = {
    variant: PropTypes.oneOf([
        AlertVariantKind.Success,
        AlertVariantKind.Error,
        AlertVariantKind.Info,
        AlertVariantKind.Warning,
    ]),
    fontSize: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
    IconProps: PropTypes.object,
};

export default AlertIcon;
