import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";

const DialogCloseButton = (props) => {
    const { t } = useTranslation();

    return (
        <div className="p-0 top-[10px] right-[10px] absolute z-10">
            <IconButton
                aria-label={props["aria-label"] || t("common.close")}
                onClick={props.onClick}
                size="large"
            >
                <CloseIcon />
            </IconButton>
        </div>
    );
};

DialogCloseButton.propTypes = {
    onClick: PropTypes.func.isRequired,
    "aria-label": PropTypes.string,
};

export default DialogCloseButton;
