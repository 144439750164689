import { useState, useEffect, useMemo } from "react";
import { useLocation } from "react-router-dom";
import RouteContext from "./RouteContext";

/**
 * @typedef RouteProviderProps
 * @property {React.ReactNode} children - Child components that will have access to the route context.
 */

/**
 * RouteProvider component that listens to route changes and provides the current and previous routes to the context.
 * @param {RouteProviderProps} props - The props for the RouteProvider component.
 * @returns {React.ReactElement} The provider component wrapping child components.
 */
const RouteProvider = ({ children }) => {
    const location = useLocation();
    const [currentPath, setCurrentPath] = useState(location.pathname);
    const [prevPath, setPrevPath] = useState("");

    useEffect(() => {
        if (location.pathname !== currentPath) {
            setPrevPath(currentPath);
            setCurrentPath(location.pathname);
        }
    }, [location, currentPath]);

    const contextValue = useMemo(
        () => ({
            currentPath,
            prevPath,
        }),
        [currentPath, prevPath]
    );

    return <RouteContext.Provider value={contextValue}>{children}</RouteContext.Provider>;
};

export default RouteProvider;
