import { type ReactNode } from "react";
import UpdateNameContext from "../contexts/UpdateNameContext";
import useUpdateName from "../hooks/useUpdateName";

interface UpdateNameProviderProps {
    children: ReactNode;
}

const UpdateNameProvider = ({ children }: UpdateNameProviderProps) => {
    const { isLoading, isSuccess, isError, updateName, clearUpdateNameState } = useUpdateName();

    return (
        <UpdateNameContext.Provider
            value={{
                isLoading,
                isSuccess,
                isError,
                updateName,
                clearUpdateNameState,
            }}
        >
            {children}
        </UpdateNameContext.Provider>
    );
};

export default UpdateNameProvider;
