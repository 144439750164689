import {
    useEffect,
    useState,
    useCallback,
    type ReactElement,
    useMemo,
    type ReactNode,
} from "react";
import is from "@sindresorhus/is";
import { v4 as uuidv4 } from "uuid";
import { isStringWithContent } from "../../../utils/validationUtils";
import Snackbar from "./Snackbar";
import SnackbarContext from "./SnackbarContext";
import { type Snack, type SnackbarContextValue, type SnackOptions } from "./snackbarTypes";

interface SnackbarProviderProps {
    children: ReactElement;
}

// Manages the snacks
const SnackbarProvider = ({ children }: SnackbarProviderProps) => {
    const [snacks, setSnacks] = useState<Snack[]>([]);
    const [currentSnack, setCurrentSnack] = useState<Snack>({});

    // When the snacks array changes, set the current snack
    useEffect(() => {
        if (snacks.length > 0) {
            const lastSnack = snacks[snacks.length - 1];
            setCurrentSnack(lastSnack);
        }
    }, [snacks]);

    /**
     * Removes the last snack from the snacks array
     * @returns {void}
     */
    const removeCurrentSnack = useCallback(() => {
        setCurrentSnack({});
        const snacksWithoutLastElement = snacks.slice(0, snacks.length - 1);
        setSnacks(snacksWithoutLastElement);
    }, [snacks]);

    // When the currentSnack changes, if applicable, set the timeout for the currentSnack
    useEffect(() => {
        if (
            !is.nullOrUndefined(currentSnack.content) &&
            !is.nullOrUndefined(currentSnack.timeout)
        ) {
            const timeout = currentSnack?.timeout;

            const timer = setTimeout(() => {
                removeCurrentSnack();
            }, timeout);

            return () => clearTimeout(timer);
        }
    }, [currentSnack, removeCurrentSnack]);

    // Memoized setter for adding snackbars
    const addSnack = useCallback((content: ReactNode, options: SnackOptions = {}) => {
        setSnacks((snacks) => {
            // Prevent duplicate messages
            if (
                isStringWithContent(options.id) &&
                snacks.some((snack) => snack.id === options.id)
            ) {
                return snacks;
            } else {
                const newSnack = { content, id: uuidv4(), ...options };
                return [newSnack, ...snacks];
            }
        });
    }, []);

    const handleSnackbarClose = () => {
        removeCurrentSnack();
    };

    const snackbarContextValue: SnackbarContextValue = useMemo(() => ({ addSnack }), [addSnack]);

    return (
        <SnackbarContext.Provider value={snackbarContextValue}>
            {children}
            {!is.nullOrUndefined(currentSnack?.content) && (
                <Snackbar
                    open={true}
                    onClose={handleSnackbarClose}
                    content={currentSnack.content}
                    variant={currentSnack.variant}
                    className={currentSnack.className}
                />
            )}
        </SnackbarContext.Provider>
    );
};

export default SnackbarProvider;
