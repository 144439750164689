import urlStrings from "../../constants/urlStrings";

const windowPostMessage = (event: string) => {
    const allowedOrigins = [urlStrings.marketingSite, urlStrings.stagingMarketingSite];

    if (window.opener) {
        allowedOrigins.forEach((targetOrigin) => {
            window.opener.postMessage(event, targetOrigin);
        });
    }
};

export default windowPostMessage;
