import { createContext, type ReactNode, useCallback, useContext, useMemo, useState } from "react";
import Dialog, { type DialogProps } from "../../components/common/Dialog/Dialog";

interface ModalContextValue {
    openModal: (props?: Partial<DialogProps>) => void;
}

interface ModalProviderProps {
    children: ReactNode;
}

const ModalContext = createContext<ModalContextValue>({
    openModal: () => {},
});

const useModal = () => useContext(ModalContext);

const ModalProvider = ({ children }: ModalProviderProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [modalProps, setModalProps] = useState<Partial<DialogProps> | undefined>({});

    const handleHide = () => {
        setIsOpen(false);
        setModalProps({});
    };

    const openModal = useCallback((props?: Partial<DialogProps>) => {
        setModalProps(props);
        setIsOpen(true);
    }, []);

    const modalContextValue: ModalContextValue = useMemo(
        () => ({
            openModal,
        }),
        [openModal]
    );

    return (
        <ModalContext.Provider value={modalContextValue}>
            {children}
            <Dialog show={isOpen} onHide={handleHide} {...modalProps} />
        </ModalContext.Provider>
    );
};

export { useModal, ModalProvider as default };
