// TODO: MVP-7147 Fix legacy jsdoc/require-returns rule violations
/* eslint-disable jsdoc/require-returns */
// TODO: MVP-7140 Fix legacy jsdoc/require-property-description rule violations
/* eslint-disable jsdoc/require-property-description */
import { useEffect, useState } from "react";
import Close from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import Portal from "@mui/material/Portal";
import classNames from "classnames";
import { useTranslation } from "react-i18next";

/**
 * @typedef DrawerBasePropsType
 * @property {JSX.Element | JSX.Element[]} children
 * @property {boolean} open
 * @property {() => void} onClose
 * @property {boolean} [disableCloseActiveBeacon] prevents closing open Beacons
 */

const beaconCloseButtonColor = "rgb(116, 132, 148)";

const STYLES = {
    default:
        "beacon-drawer bg-white right-0 position-fixed h-screen max-h-full w-[360px] overflow-y-scroll",
};

const closeBeacon = () => window.Beacon("close");

/**
 * Updates classes to create a javascript-driven transition
 * This variable, consumed by a component, will transition through four classes as the open prop changes
 * - enter-active: open is true and we're ready to start the open transition
 * - enter-done:   the enter transition occurs and we end with our "open" class
 * - exit-active:  open is false and we're ready to start closed transition
 * - exit-done:    the exit transition occurs and we end with our "closed" class
 * This series of class changes allows us to end with `display: none` which, while preferably for usability, is not transitionable
 * @param {boolean} open - state of the drawer to be transitioned
 * @returns {string} - the class name
 */
const useDrawerTransition = (open) => {
    const [drawerClass, setDrawerClass] = useState(
        open ? `drawer-enter-active` : `drawer-exit-active`
    );

    useEffect(() => {
        if (open) {
            setDrawerClass(`drawer-enter-active`);
            setTimeout(() => {
                setDrawerClass(`drawer-enter-done`);
            }, 100);
        } else {
            setDrawerClass(`drawer-exit-active`);
            setTimeout(() => {
                setDrawerClass(`drawer-exit-done`);
            }, 300);
        }
    }, [open]);

    return drawerClass;
};

/* eslint-disable-next-line valid-jsdoc */
/** @param {DrawerBasePropsType} props - Props for the DrawerBase component */
const DrawerBase = (props) => {
    const { t } = useTranslation();

    const drawerClass = useDrawerTransition(props.open);
    const styles = classNames(STYLES.default, drawerClass);

    useEffect(() => {
        if (props.open && !props.disableCloseActiveBeacon) {
            closeBeacon();
        }
    }, [props.disableCloseActiveBeacon, props.open]);

    useEffect(() => {
        if (!props.disableCloseActiveBeacon) {
            window.Beacon("on", "article-viewed", props.onClose);
        }
        return () => window.Beacon("off", "article-viewed");
    }, [props.disableCloseActiveBeacon, props.onClose]);

    return (
        <Portal>
            <div id="customBeaconDrawer" className={styles}>
                <div className="position-fixed right-1">
                    <IconButton
                        onClick={props.onClose}
                        size="medium"
                        aria-label={t("common.help_button")}
                    >
                        <Close style={{ color: beaconCloseButtonColor }} />
                    </IconButton>
                </div>
                <div className="p-9">{props.children}</div>
            </div>
        </Portal>
    );
};

export default DrawerBase;
