// TODO: MVP-7140 Fix legacy jsdoc/require-property-description rule violations
/* eslint-disable jsdoc/require-property-description */
import { useCallback, useRef } from "react";
import PropTypes from "prop-types";
import useResizeObserver from "../../../hooks/useResizeObserver";

/**
 * @typedef Size props
 * @property {number} height
 * @property {number} width
 */

/**
 * @typedef Props props
 * @property {object} [children]
 * @property {(size: Size) => void} onSizeChange
 * @property {string} [className]
 */

/**
 * Component that allows measuring children size.
 * @param {Props} props - Props
 * @returns {JSX.Element} - Container
 */
const MeasurableContainer = (props) => {
    const ref = useRef(null);
    const { onSizeChange } = props;

    const onResize = useCallback(
        ([event]) => {
            const { height, width } = event.contentRect;
            onSizeChange({
                height,
                width,
            });
        },
        [onSizeChange]
    );

    useResizeObserver({ target: ref, onResize, enabled: true });

    return (
        <div ref={ref} className={props.className}>
            {props.children}
        </div>
    );
};

MeasurableContainer.propTypes = {
    children: PropTypes.node.isRequired,
    onSizeChange: PropTypes.func.isRequired,
    className: PropTypes.string,
};

export default MeasurableContainer;
