const SRC_DOMAIN = process.env.REACT_APP_IMG_SRC_DOMAIN;

export default {
    analyzeBanner: `https://${SRC_DOMAIN}/images/create_case_intro.svg`,
    assessToolBanner: `https://${SRC_DOMAIN}/images/assess_tool_banner.svg`,
    caseDashboard: `https://${SRC_DOMAIN}/images/case_dashboard_banner.svg`,
    certifiedMailDemand: `https://${SRC_DOMAIN}/images/certified_mail_demand.svg`,
    certifiedMailSettlement: `https://${SRC_DOMAIN}/images/certified_mail_settlement.svg`,
    coaBackground: `https://${SRC_DOMAIN}/images/coa_background.svg`,
    considerSettlementBanner: `https://${SRC_DOMAIN}/images/defendant_consider_settlement_banner.svg`,
    defendantAssessCaseBanner: `https://${SRC_DOMAIN}/images/defendant_assess_case_banner.svg`,
    defendantButtonIcon: `https://${SRC_DOMAIN}/images/defendantIcon.svg`,
    defendantCaseIcon: `https://${SRC_DOMAIN}/images/defendantCaseIcon.svg`,
    defendantCourtPrepInfoDemo: `https://${SRC_DOMAIN}/images/D_courtPrepInfo_Demo.pdf`,
    defendantSignStartBanner: `https://${SRC_DOMAIN}/images/defendant_sign_start_quest_banner.svg`,
    defendantTrialPresentationDemo: `https://${SRC_DOMAIN}/images/D_TrialPresentation_Demo.pdf`,
    defendantTrialPresentationJudgeDemo: `https://${SRC_DOMAIN}/images/D_TrialPresentation_JudgeCopy_Demo.pdf`,
    defendantTrialPresentationPlaintiffDemo: `https://${SRC_DOMAIN}/images/D_TrialPresentation_PlaintiffCopy_Demo.pdf`,
    demandIntro: `https://${SRC_DOMAIN}/images/demand_payment_intro.svg`,
    fileCaseBanner: `https://${SRC_DOMAIN}/images/file_case_banner.svg`,
    justiceLawFirmLogo: `https://${SRC_DOMAIN}/images/justiceLawFirmLogo.svg`,
    justiceLawFirmLogoBlackLetters: `https://${SRC_DOMAIN}/images/justiceLawFirmLogoBlackLetters.svg`,
    justiceDirectServiceOfProcessCoversheet: `https://${SRC_DOMAIN}/images/JusticeDirect_Service_of_Process_Coversheet.pdf`,
    plaintiffButtonIcon: `https://${SRC_DOMAIN}/images/plaintiffIcon.svg`,
    plaintiffCaseIcon: `https://${SRC_DOMAIN}/images/plaintiffCaseIcon.svg`,
    prepareTrialBannerDefendant: `https://${SRC_DOMAIN}/images/prepare_trial_banner_defendant.svg`,
    prepareTrialBannerPlaintiff: `https://${SRC_DOMAIN}/images/prepare_trial_banner_plaintiff.svg`,
    recipientsBanner: `https://${SRC_DOMAIN}/images/recipients_banner.svg`,
    sendersBanner: `https://${SRC_DOMAIN}/images/senders_banner.svg`,
    somethingWentWrong: `https://${SRC_DOMAIN}/images/errorPageIcon.svg`,
    startBanner: `https://${SRC_DOMAIN}/images/justice_plan_banner.svg`,
    trialFlowBanner: `https://${SRC_DOMAIN}/images/trialflow_start_banner.svg`,
    trialPresentationPlaintiffUpsellMobile: `https://${SRC_DOMAIN}/images/trial_presentation_upsell_mobile.png`,
    trialPresentationPlaintiffUpsellDesktop: `https://${SRC_DOMAIN}/images/trial_presentation_upsell_desktop.png`,
    trialPresentationDefendantUpsellMobile: `https://${SRC_DOMAIN}/images/defendantTP_upsell_mobile.png`,
    trialPresentationDefendantUpsellDesktop: `https://${SRC_DOMAIN}/images/defendantTP_upsell_desktop.png`,
    sc101: `https://${SRC_DOMAIN}/images/sc101.pdf`,
    sc109: `https://${SRC_DOMAIN}/images/sc109.pdf`,
    fw001: `https://${SRC_DOMAIN}/images/fw001.pdf`,
    mc410: `https://${SRC_DOMAIN}/images/mc410.pdf`,
    int300: `https://${SRC_DOMAIN}/images/int300.pdf`,
    cccbaLogoHorizontal: `https://${SRC_DOMAIN}/images/cccba_logo_dark_horizontal.png`,
    cccbaBadge: `https://${SRC_DOMAIN}/images/cccba_logo_dark_badge.png`,
    cccbaMountains: `https://${SRC_DOMAIN}/images/cccba_mountains.svg`,
    peopleClerkLogo: `https://${SRC_DOMAIN}/images/peopleclerk_logo.png`,
    patrickAvatar: `https://${SRC_DOMAIN}/images/patrick_avatar.png`,
};
