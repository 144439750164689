import { createContext, type ReactNode, useContext, useState } from "react";

interface TooltipContextProps {
    isTooltipOpen: (id: string) => boolean;
    setTooltipState: (id: string, open: boolean) => void;
    toggleTooltip: (id: string) => void;
    tooltipStates: Record<string, boolean>;
}

interface TooltipProviderProps {
    children: ReactNode;
}

const TooltipContext = createContext<TooltipContextProps>({
    isTooltipOpen: () => false,
    setTooltipState: () => {},
    toggleTooltip: () => {},
    tooltipStates: {},
});

export const TooltipProvider = ({ children }: TooltipProviderProps) => {
    const [tooltipStates, setTooltipStates] = useState<Record<string, boolean>>({});

    const isTooltipOpen = (id: string) => tooltipStates[id] ?? false;

    const setTooltipState = (id: string, isOpen: boolean) => {
        setTooltipStates((prevTooltipStates) => ({
            ...prevTooltipStates,
            [id]: isOpen,
        }));
    };

    const toggleTooltip = (id: string) => {
        setTooltipStates((prevTooltipStates) => ({
            ...prevTooltipStates,
            [id]: !prevTooltipStates[id],
        }));
    };

    return (
        <TooltipContext.Provider
            value={{ isTooltipOpen, setTooltipState, toggleTooltip, tooltipStates }}
        >
            {children}
        </TooltipContext.Provider>
    );
};

export const useTooltipContext = () => useContext(TooltipContext);
