import { useEffect, useMemo, useState } from "react";
import is from "@sindresorhus/is";
import { jwtDecode } from "jwt-decode";
import log from "loglevel";
import cookieManager from "../../services/cookieManager";
import useAuth from "../auth/hooks/useAuth";
import AuthSessionContext from "./AuthSessionContext";
import authSessionEventListener from "./authSessionEventListener";
import ExpireSessionEvent from "./events/ExpireSessionEvent";
import useTimerUntilDate from "./hooks/useTimerUntilDate";

/**
 * @typedef AuthSessionProviderProps
 * @property {React.ReactElement} children - Children.
 */

/**
 * AuthSessionProvider
 * @param {AuthSessionProviderProps} props - AuthSessionProvider props.
 * @returns {React.ReactElement} - AuthSessionProvider component.
 */
const AuthSessionProvider = ({ children }) => {
    const { isLoggedIn, shouldExpireSession, isSessionExpired, authMachineActions } = useAuth();

    const { onResetExpireSession, onExpireSession, onResetSessionExpired, onSessionExpired } =
        authMachineActions;

    const [expirationDate, setExpirationDate] = useState(null);

    useEffect(() => {
        authSessionEventListener.addEventListener(ExpireSessionEvent.eventType, onExpireSession);

        return () => {
            authSessionEventListener.removeEventListener(
                ExpireSessionEvent.eventType,
                onExpireSession
            );
        };
    }, [onExpireSession]);

    useEffect(() => {
        let _expirationDate = null;

        if (isLoggedIn) {
            const accountDetails = cookieManager.getAccountDetails();

            if (!is.nonEmptyStringAndNotWhitespace(accountDetails?.authToken)) {
                return;
            }

            try {
                const tokenDetails = jwtDecode(accountDetails.authToken);

                const date = new Date(tokenDetails.exp * 1000);

                _expirationDate = date;
            } catch (err) {
                log.error(
                    `Unable to set expiration date. Could not parse authToken ${accountDetails.authToken}: ${err.message}`
                );
            }
        }

        setExpirationDate(_expirationDate);
    }, [isLoggedIn]);

    useTimerUntilDate(expirationDate, onExpireSession);

    const value = useMemo(
        () => ({
            shouldExpireSession,
            isSessionExpired,
            resetExpireSession: onResetExpireSession,
            onExpireSession,
            resetSessionExpired: onResetSessionExpired,
            onSessionExpired,
        }),
        [
            shouldExpireSession,
            isSessionExpired,
            onResetExpireSession,
            onExpireSession,
            onResetSessionExpired,
            onSessionExpired,
        ]
    );

    return <AuthSessionContext.Provider value={value}>{children}</AuthSessionContext.Provider>;
};

export default AuthSessionProvider;
